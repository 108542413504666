<template>
  <b-form>
    <b-card title="Ekle">
      <b-form-group
        label="Soru"
        label-for="question"
      >
        <b-form-input
          id="question"
          v-model="poll.question"
          placeholder="Başlık"
        />
      </b-form-group>
      <div
        v-for="(option,key) in poll.options"
        :key="key"
      >
        <b-form-group
          :label="(key + 1) + '. Yanıt'"
          :label-for="'option_' + key"
        >
          <b-form-input
            :id="'option_' + key"
            v-model="option.title"
            placeholder="Yanıt"
          />
        </b-form-group>
      </div>
      <b-form-group
        label="Anket Durumu"
        label-for="status"
      >
        <v-select
          id="status"
          v-model="poll.status"
          placeholder="Durum"
          :options="statuses"
          label="title"
          :reduce="status => status.id"
        />
      </b-form-group>
    </b-card>
    <b-button
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BFormInput,
  BFormGroup,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Add',
  components: {
    BForm,
    BCard,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  data() {
    return {
      submitStatus: false,
      statuses: [
        {
          id: '1',
          title: 'Açık',
        },
        {
          id: '0',
          title: 'Kapalı',
        },
      ],
    }
  },
  computed: {
    poll() {
      return this.$store.getters['polls/getPoll']
    },
    saveStatus() {
      return this.$store.getters['polls/getPollSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          title: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.dispatch('polls/pollReset')
    this.getPoll()
  },
  methods: {
    getPoll() {
      this.$store.dispatch('polls/pollView', this.$route.params.id)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.poll.submitStatus = true
      this.$store.dispatch('polls/pollSave', this.poll)
    },
  },
}
</script>
